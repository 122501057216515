<template>
  <div
    class="modal fade modal-data-pack"
    ref="modalTc"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-scrollable">
      <div class="modal-content">
        <div class="modal-header pb-1">
          <h5 class="text-blue" v-if="type === 'tnc'">Terms & Conditions</h5>
          <h5 class="text-blue" v-else>Privacy Policy</h5>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <!-- Nav tabs -->
              <!-- <ul class="nav nav-tabs nav-fill">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#tc"
                    >Terms & Conditions</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#pp"
                    >Privacy Policy</a
                  >
                </li>
              </ul> -->

              <!-- Tab panes -->
              <!-- <div class="tab-content"> -->
              <div style="height: 90vh">
                <object
                  v-show="type == 'tnc'"
                  :data="`${terms.default}#toolbar=0&navpanes=0&scrollbar=0`"
                  width="100%"
                  height="100%"
                  class="py-3"
                ></object>
                <object
                  v-show="type == 'pp'"
                  :data="`${privacy.default}#toolbar=0&navpanes=0&scrollbar=0`"
                  width="100%"
                  height="100%"
                  class="py-3"
                ></object>
              </div>
              <!-- </div> -->
              <!-- <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#"
                    >Active</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
              </ul> -->
              <!-- <pdf :src="terms.default"></pdf> -->
              <!-- <vue-pdf-embed
                :source="terms.default"
                @rendering-failed="log($event)"
              /> -->
              <!-- <h5 class="text-blue">Terms & Conditions</h5>
              <object
                :data="`${terms.default}#toolbar=0&navpanes=0&scrollbar=0`"
                width="100%"
                height="50%"
              ></object>

              <h5 class="text-blue">Privacy Policy</h5>
              <object
                :data="`${privacy.default}#toolbar=0&navpanes=0&scrollbar=0`"
                width="100%"
                height="50%"
              ></object> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-s2s-blue"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
// import pdf from "vue-pdf";
export default {
  components: {
    // VuePdfEmbed,
    // pdf,
  },
  data() {
    return {
      modal: null,
      resolve: null,
      reject: null,
      submitted: false,
      type: "tnc",
    };
  },
  methods: {
    open(type) {
      this.type = type;
      setTimeout(() => {
        this.modal.show();
      }, 500);

      // this.title = title;
      // this.message = message;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      // this.dialog = false;
      this.modal.hide();
    },
    cancel() {
      this.resolve(false);
      // this.dialog = false;
      this.modal.hide();
    },

    log(e) {
      console.log(e);
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalTc, {
      keyboard: false,
    });
  },

  computed: {
    terms() {
      return require("../assets/pdf/tnc_new.pdf");
    },

    privacy() {
      return require("../assets/pdf/privacy_new.pdf");
    },
  },

  created() {
    // this.render()
  },
};
</script>

<style scoped>
.accordion-button {
  padding: 10px 10px;
}
</style>
